import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
import { Unit } from '@interfaces/LearningPath'
import findIndex from 'lodash/findIndex'
import { FormValueProps, IAvailabilityBulkPayload } from '@components/Modal/AvailabilityBulkDeleteModal/interface'
import PortalInstructorAvailability from '@classes/InstructorAvailability'

const moment = extendMoment(Moment)

// more common helpers here
let queryString: any = null

if (typeof window !== 'undefined') {
  // tslint:disable-next-line:no-var-requires
  queryString = require('query-string')
}

export const getQueryParams = (field: string, url?: string) => {
  if (!queryString || !window) {
    return null
  }
  const removeHash = url?.split('#')?.[0]
  const cleanedUrl = removeHash?.split('?').pop()

  const currentQuery = queryString.parse(cleanedUrl || window.location.search) || {}
  return (currentQuery[field] || '').replace(' ', '+')
}

export const removeQueryParams = (fields: string[], url?: string) => {
  if (!queryString || !window) {
    return null
  }

  const parsed = queryString.parse(url || window.location.search)

  fields.forEach((currentField) => {
    delete parsed[currentField]
  })
  const { origin, pathname } = window.location
  const separator = Object.keys(parsed).length === 0 ? '' : '?'
  let finalUrl = `${origin}${pathname}${separator}${queryString.stringify(parsed)}`
  finalUrl = finalUrl.endsWith('?') ? finalUrl.slice(0, -1) : finalUrl
  return finalUrl
}

export const formQueryString = (obj) => queryString.stringify(obj)

export const createMarkup = (markup) => ({ __html: markup })

export const getFileExtension = (filename, defaultExt = 'others') =>
  (filename.split('.').pop() || defaultExt).toLowerCase()

export const isEmail = (email) => /\S+@\S+\.\S+/.test(email)

export const isGodMode = (isAdmin?: boolean) =>
  isAdmin && (getQueryParams('godMode') === '1' || localStorage.getItem('gm') === '1')

export const getUnitPrevIndex = (units: Unit[], unitid: number, depth: number = -1) => {
  const currentIndex = findIndex(units, { unitid })
  return currentIndex > 0 ? currentIndex - depth : currentIndex
}

export const convertMomentToDate = (date: moment.Moment = moment()) => new Date(date.clone().format('llll'))

export const urlStringify = (params: { [key: string]: any }) => {
  if (!queryString || !window) {
    return undefined
  }

  return queryString.stringify(params)
}

export const getFilteredBulkDeletePayload = (
  values: FormValueProps,
  Availabilities: PortalInstructorAvailability[]
): IAvailabilityBulkPayload[] => {
  return Availabilities.filter(({ CalendarStart, CalendarEnd, Type }) => {
    const EventStart = moment.tz(CalendarStart, values.Timezone)
    const EventEnd = moment.tz(CalendarEnd, values.Timezone)
    const eventRange = moment.range(EventStart, EventEnd)
    const selectedRange = moment.range(values.StartDate.clone().startOf('day'), values.EndDate.clone().endOf('day'))
    return (
      values.AvailabilityType.toLowerCase() === Type.toLowerCase() &&
      selectedRange.contains(eventRange) &&
      values.RepeatOn.includes(EventStart.weekday())
    )
  }).map((Availability) => Availability.getBulkDeletePayload(values.KeepLessons))
}

export const getIdAndTypeforProfile = (user: any) => {
  if (
    user?.attributes?.attributes?.['custom:customerId'] ||
    user?.signInUserSession?.idToken?.payload?.['custom:customerId']
  ) {
    return {
      id:
        user?.attributes?.attributes?.['custom:customerId'] ||
        user?.signInUserSession?.idToken?.payload?.['custom:customerId'] ||
        0,
      type: 'StudentId',
    }
  } else if (
    user?.attributes?.attributes?.['custom:federatedExternalId'] ||
    user?.signInUserSession?.idToken?.payload?.['custom:federatedExternalId']
  ) {
    return {
      id:
        user?.attributes?.attributes?.['custom:federatedExternalId'] ||
        user?.signInUserSession?.idToken?.payload?.['custom:federatedExternalId'] ||
        0,
      type: 'ExternalIdentifier',
    }
  } else if (user?.attributes?.attributes?.email || user?.signInUserSession?.idToken?.payload?.email) {
    return {
      id: user?.attributes?.attributes?.email || user?.signInUserSession?.idToken?.payload?.email || '',
      type: 'Email',
    }
  } else {
    return {
      id: 0,
      type: 'StudentId',
    }
  }
}

export const getIdAndTypeForProfForAdmin = (accessAs: any) => {
  const { aas, aai } = accessAs
  if (aai) {
    return {
      id: aai,
      type: 'Email',
    }
  } else if (aas) {
    return {
      id: aas,
      type: 'StudentId',
    }
  } else {
    return {
      id: 0,
      type: 'StudentId',
    }
  }
}

export const extractNumbeFromStr = (input: string | null | undefined): number | null => {
  if (!input) return null // Handle null or undefined input

  const numberMatch = input.match(/\d+/)
  return numberMatch ? parseInt(numberMatch[0], 10) : null
}
