import React from 'react'
import NotSetBanner from './NotSetBanner'
import OnlinePlacementTestBanner from './OnlinePlacementTestBanner'
import { OnlinePlacementTestProps } from './interface'

const OnlinePlacementTest: React.FC<OnlinePlacementTestProps> = ({ programId, registrationId, showNotSetBanner }) => {
  if (showNotSetBanner) {
    return <NotSetBanner />
  }

  return <OnlinePlacementTestBanner programId={programId} registrationId={registrationId} />
}

export default OnlinePlacementTest
