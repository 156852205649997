import MyBerlitzLesson from '@classes/Lesson'
import LessonCountdown from '@classes/LessonCountdown'
import { RectLoader } from '@components/Loader'
import { WidgetHeader } from '@components/Widgets/Common'
import {
  Countdown,
  DateTime,
  EventCardHeader,
  EventDate,
  EventSecondHeader,
  Header,
} from '@components/Widgets/NextLesson/style'
import { StoreProps } from '@interfaces/StoreState'
import { PROFILE_TYPE } from '@utils/constants'
import React, { useEffect, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

interface NextLessonProps {
  loading: boolean
  isEvent?: boolean
  startDate?: string
  timerange?: string
  lesson?: MyBerlitzLesson
  countdown: LessonCountdown
}
const NextLessonHeader: React.FC<NextLessonProps> = ({
  isEvent = false,
  startDate,
  loading,
  timerange,
  lesson,
  countdown,
}) => {
  const intl = useIntl()
  const { isStudent, userPermissions } = useSelector(({ userProfile, permissions }: StoreProps) => ({
    userId: userProfile?.info?.SFId,
    isStudent: userProfile?.info?.Type === PROFILE_TYPE.STUDENT,
    userPermissions: permissions.permissions,
  }))
  const showCountdown = userPermissions['flag::NextLessonCountdown-feature']?.show
  const showEventCD = userPermissions['flag::NextEventCountdown-feature']?.show
  const inProgressText = `${isEvent ? 'Event' : 'Lesson'} In Progress`
  const [cdText, setCDText] = useState(countdown?.formatCountdown(intl))
  const [headerText, setHeaderText] = useState(
    countdown?.isInProgress() && showCountdown ? inProgressText : isEvent ? 'Next confirmed activity' : 'Next lesson'
  )
  const [isStudentWithCountdown, setIsStudentWithCountdown] = useState(
    isStudent && showCountdown && countdown.shouldShowCountdown() && !isEvent
  )

  const [isEventWithCountdown, setIsEventWithCountdown] = useState(
    isStudent && showEventCD && countdown.shouldShowCountdown() && isEvent
  )

  const useEventStyling = useMemo(() => showEventCD && isEvent, [showEventCD, isEvent])

  useEffect(() => {
    const showCD = isStudent && countdown.shouldShowCountdown()
    setIsStudentWithCountdown(showCD && showCountdown && !isEvent)
    setIsEventWithCountdown(showCD && showEventCD && isEvent)
  }, [isStudent, showCountdown, countdown, isEvent])

  useEffect(() => {
    if (!(lesson || isEvent) || !(showCountdown || (showEventCD && isEvent)) || !isStudent) {
      return
    }

    const timer = setInterval(() => {
      const showCD = isStudent && countdown.shouldShowCountdown()
      if (!isStudentWithCountdown && countdown.shouldShowCountdown()) {
        setIsStudentWithCountdown(showCD && showCountdown && !isEvent)
      }

      if (!isEventWithCountdown && countdown.shouldShowCountdown()) {
        setIsEventWithCountdown(showCD && showEventCD && isEvent)
      }

      if (countdown.formatCountdown(intl) !== cdText) {
        setCDText(countdown.formatCountdown(intl))
      }

      if (countdown.isInProgress() && headerText !== inProgressText) {
        setHeaderText(inProgressText)
        setIsStudentWithCountdown(false)
        setIsEventWithCountdown(false)
      }
    }, 10 * 1000)

    return () => {
      clearInterval(timer)
    }
  }, [lesson, isEvent, countdown, cdText, headerText, isStudentWithCountdown, isEventWithCountdown])

  return (
    <WidgetHeader
      customStyling={useEventStyling ? { paddingBottom: '8px' } : undefined}
      withCountdown={isStudentWithCountdown}
      title={
        <Header isEvent={isEvent} useEventStyling={useEventStyling}>
          <div className="title">
            {isEventWithCountdown ? (
              <>
                <EventCardHeader>
                  <div>
                    <FormattedMessage id="Next confirmed activity" />
                  </div>
                  <EventDate>{startDate}</EventDate>
                </EventCardHeader>

                <EventSecondHeader>
                  <div>
                    <Countdown danger={countdown?.isMinutesLeft}>{cdText}</Countdown>
                  </div>
                  <div>{timerange}</div>
                </EventSecondHeader>
              </>
            ) : isEvent ? (
              <>
                <FormattedMessage id={isStudent ? headerText : 'Next confirmed activity'} />
                <div className="date-time">
                  <div>{startDate}</div>
                  <div>{timerange}</div>
                </div>
              </>
            ) : isStudentWithCountdown ? (
              <>
                <FormattedMessage id="Next lesson" />
                <Countdown danger={countdown.isMinutesLeft}>{cdText}</Countdown>
              </>
            ) : (
              <FormattedMessage id={isStudent ? headerText : 'Next confirmed activity'} />
            )}
          </div>

          {!isEvent && (
            <DateTime>
              {(() => {
                if (loading) {
                  return (
                    <>
                      <div>
                        <RectLoader height="15px" width="120px" />
                      </div>
                      <div>
                        <RectLoader height="15px" width="80px" />
                      </div>
                    </>
                  )
                }
                return startDate ? (
                  <>
                    <div>{startDate}</div>
                    <div>{timerange}</div>
                  </>
                ) : null
              })()}
            </DateTime>
          )}
        </Header>
      }
      color="disabled"
      headerColor="brandPrimary"
    />
  )
}

export default NextLessonHeader
