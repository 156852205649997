import React from 'react'
import { Container } from './style'
import PortalStudentProfile from '@classes/StudentProfile'
import { StoreProps } from '@interfaces/StoreState'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import Loader from './Loader'
import DefaultOPT from './DefaultOPT'
import OPTPicker from './OPTPicker'
import useOnlinePlacementTest from '@hooks/useOnlinePlacementTest'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import { OnlinePlacementTestProps } from './interface'

const OnlinePlacementTestBanner: React.FC<OnlinePlacementTestProps> = ({ programId, registrationId }) => {
  const portalStudentInfo = useSelector(
    ({ userProfile, dashboard }: StoreProps) =>
      new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo, dashboard?.activeMaterial ?? '')
  )

  let regId = registrationId
  if (!regId && programId) {
    const registration = portalStudentInfo.getRegistrationByProgramId(programId)
    regId = registration?.RegistrationInfo?.RegistrationId
  }
  const { loading: PlacementTestLoading, data: PlacementTest } = useOnlinePlacementTest(regId ?? '')

  const hasOPT = Boolean(PlacementTest && PlacementTest?.getOnlinePlacementTest?.length !== 0)
  /**
   * We need to show loader while PlacementTest is equal to {}
   * since it means that the query is still loading
   */
  const loading = isEqual(PlacementTest, {}) || PlacementTestLoading

  const { state } = usePortalFeatures({
    configId: 'flag::OPT-AssessmentUI',
  })

  /**
   * if PlacementTest is
   * then display the PlacementTestSelector component
   * otherwise display Default component
   */
  if (loading) {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }

  if (state.allowed && hasOPT) {
    return <OPTPicker key={registrationId} programId={programId} registrationId={registrationId} />
  }

  return <DefaultOPT programId={programId} registrationId={registrationId} />
}

export default OnlinePlacementTestBanner
