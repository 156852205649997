import PortalLearningPathUnit from '@classes/LearningPathUnit'
import MyBerlitzLessons from '@classes/Lessons'
import PortalPrograms from '@classes/Programs'
import PortalStudentProfile from '@classes/StudentProfile'
import { LMSLearningPlans, LMSLearningPlansUnits } from '@interfaces/LearningPath'
import { RegistrationMaterial } from '@interfaces/Student'
import {
  AllLevelsCompletedLocalStorageKey,
  AllLevelsCompletedModalLocalStorageKey,
  CURRICULUM_TEST_STATUS,
  ELECTIVE,
  FLEX,
  FLEX_LEVEL_STATUS,
  FLEX_PRIVATE_ONLINE_ACTIVATION,
  FLEX_VERSION,
  PROGRAM_TYPES,
  REG_STATUS,
} from '@utils/constants'
import {
  CULTURE_ACTIVITY_TYPE,
  FLEX_ACTIVITY_LINK,
  LP_STATUS,
  LP_UNIT_LESSON_STATUS,
  LP_UNIT_TYPE,
} from '@utils/constants/learningPath'
import { addBusinessDays } from '@utils/helpers/dates'
import { getLanguageName } from '@utils/helpers/languages'
import { getPermissionFromStorage } from '@utils/helpers/permissions'
import moment from 'moment-timezone'
import PortalRegistrations from './Registrations'
import { last } from 'lodash'

class PortalLearningPath {
  learningPath?: LMSLearningPlans
  StudentPrograms?: PortalPrograms
  StudentProfile?: PortalStudentProfile
  StudentSessions?: MyBerlitzLessons
  AYCLFlags?: Boolean
  ElectivesFlag?: boolean
  Elective?: LMSLearningPlans
  Foundation?: LMSLearningPlans
  overrideUnliLCS?: boolean
  cachedFlexUnits?: PortalLearningPathUnit[]
  cachedNonFlexUnits?: PortalLearningPathUnit[]
  CurriculumTests?: PortalLearningPath[] | null | undefined
  is3rdPartyMaterial?: boolean
  is3rdPartyMaterialHasAttachments?: boolean
  tempFlags: {
    UnlimitedCoaching: string
    EnrolmentInProgress: string
    ReadyForNextLvl: string
    AllLevelsCompleted: string
    SelectElective: string
    AllLevelsCompletedModal: string
  }
  modules?: number[]
  constructor(
    learningPath?: LMSLearningPlans,
    StudentProfile?: PortalStudentProfile,
    StudentSessions?: MyBerlitzLessons,
    Elective?: LMSLearningPlans,
    Foundation?: LMSLearningPlans,
    TestMaterials?: PortalLearningPath[] | null
  ) {
    this.learningPath = learningPath
    this.StudentProfile = StudentProfile
    this.StudentPrograms = StudentProfile?.PortalPrograms
    this.StudentSessions = StudentSessions
    this.AYCLFlags = getPermissionFromStorage('flag::AYCL-feature')
    this.ElectivesFlag = getPermissionFromStorage('flag::GERMAN5-8-feature')
    this.Elective = Elective
    this.Foundation = Foundation
    this.CurriculumTests = TestMaterials
    this.tempFlags = {
      UnlimitedCoaching: `temp_${FLEX_LEVEL_STATUS.UNLIMITED_LCS}-${this.learningPath?.lpid}-${this.StudentProfile?.SFId}`,
      EnrolmentInProgress: `temp_${FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS}-${this.learningPath?.lpid}-${this.StudentProfile?.SFId}`,
      ReadyForNextLvl: `temp_${FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL}-${this.learningPath?.lpid}-${this.StudentProfile?.SFId}`,
      AllLevelsCompleted: AllLevelsCompletedLocalStorageKey(this.learningPath?.lpid, this.StudentProfile?.SFId),
      SelectElective: `temp_SELECT_ELECTIVE-${this.learningPath?.lpid}-${this.StudentProfile?.SFId}`,
      AllLevelsCompletedModal: AllLevelsCompletedModalLocalStorageKey(
        this.learningPath?.lpid,
        this.StudentProfile?.SFId
      ),
    }
    this.cachedFlexUnits = undefined
    this.cachedNonFlexUnits = undefined
  }

  getLastTwoCompleteUnits = () => {
    const units = this.Units.filter(({ Type }) => Type === LP_UNIT_TYPE.LESSON)
    return units.splice(units.length - 2, units.length)
  }

  get Id() {
    return this.learningPath?.lpid ?? ''
  }

  get ProgramName() {
    return (
      this.Material?.MaterialName ||
      this.Material?.LPExternalName ||
      this.learningPath?.details?.fullname?.[0]?.fullname ||
      ''
    )
  }

  get ContractEndDate() {
    const format = 'YYYY-MM-DD'
    return this.IsFlex
      ? moment(this.Material?.ProgramEndDate, format).format(format)
      : moment(this.Material?.ProgramEndDate, format)
          .tz(this.Program?.program?.DeliveryLCTimeZone || this.StudentProfile?.Timezone || moment.tz.guess())
          .format(format)
  }

  get HasContractEnded() {
    const endDate = this.ContractEndDate

    /**
     * If date is not valid then lets expire contract
     */
    if (!moment(endDate).isValid()) {
      return true
    }

    const tz = this.StudentProfile?.Timezone || moment.tz.guess()
    return endDate ? moment(endDate).add(1, 'day').isBefore(moment.tz(tz)) : false
  }

  get Language() {
    return getLanguageName(this.learningPath?.details?.language)
  }

  get Locale() {
    return this.learningPath?.details.language
  }

  get Status() {
    return this.learningPath?.status ?? ''
  }

  get ElectiveUnits() {
    return this.Elective?.details?.units.map((unit) => ({ ...unit, lpid: this.Elective?.lpid })) || []
  }

  get FoundationUnits() {
    return this.Foundation?.details?.units.map((unit) => ({ ...unit, lpid: this.Foundation?.lpid })) || []
  }

  get HasNoUnits() {
    return !this.AllUnits.length
  }

  get NonFlexUnits() {
    if (!this.cachedNonFlexUnits) {
      this.is3rdPartyMaterial =
        this.AllUnits.filter((unit) => unit.unittype === LP_UNIT_TYPE.MATERIAL).length === this.AllUnits.length

      this.is3rdPartyMaterialHasAttachments =
        this.is3rdPartyMaterial &&
        this.AllUnits.filter((unit) => unit.attachments.length).length === this.AllUnits.length
    }
    const unitsArray =
      this.cachedNonFlexUnits ||
      this.AllUnits.map((unit) => {
        const isFoundationUnit = !!this.FoundationUnits.find(
          ({ unitid }) => unitid === unit.unitid && unit.lpid !== this.learningPath?.lpid
        )
        const newUnit = new PortalLearningPathUnit(
          unit,
          this.Program,
          new MyBerlitzLessons(this.FilterValidSessions),
          unit.lpid || this.learningPath?.lpid,
          false,
          this.IsPreviousLP,
          !!this.ElectiveUnits.find(({ unitid }) => unitid === unit.unitid && unit.lpid !== this.learningPath?.lpid) ||
            (this.IsElective && !isFoundationUnit),
          isFoundationUnit,
          this.HasContractEnded
        )

        newUnit.Set3rdPartyMaterial = !!this.is3rdPartyMaterial
        newUnit.Is3rdPartyMaterialHasAttachments = !!this.is3rdPartyMaterialHasAttachments
        return newUnit
      }) ||
      []
    // Move course meta to last
    !this.cachedNonFlexUnits &&
      unitsArray.push(
        unitsArray.splice(
          unitsArray.findIndex((unit) => unit.IsCourseMetaData),
          1
        )[0]
      )

    const finalUnits =
      this.cachedNonFlexUnits ||
      unitsArray
        .filter(Boolean)
        .filter(
          (unit) =>
            unit.Type !== LP_UNIT_TYPE.COACHING &&
            !(unit.Type === LP_UNIT_TYPE.REVIEW && unit.Title?.toLowerCase().startsWith('review lesson'))
        )

    return this.is3rdPartyMaterial ? [finalUnits[0]].filter(Boolean) : finalUnits
  }

  get IsRedoingLP() {
    return false
  }

  get IsLastLPLevel() {
    return false
  }

  get UnlimitedCoaching() {
    // this is already determined in get FlexUnits so we just pick one and check the value
    return this.overrideUnliLCS !== undefined
      ? this.overrideUnliLCS
      : Boolean(this.FlexUnits?.[0]?.UnlimitedLCS || this.CanUnliBookLCS)
  }

  get CanUnliBookLCS() {
    return (
      this.Material?.ProgramType === FLEX_PRIVATE_ONLINE_ACTIVATION &&
      (this.Material?.FlexVersion === FLEX_VERSION.SINGLE ||
        this.Material?.FlexVersion === FLEX_VERSION.TWO ||
        this.Material?.FlexVersion === FLEX_VERSION.MULTI) &&
      (this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.UNLIMITED_LCS)
    )
  }

  set UnlimitedLCSOverride(value: boolean | undefined) {
    this.overrideUnliLCS = value
  }

  get CoachingCount() {
    return this.learningPath?.details?.units?.filter((Unit) => Unit.unittype === LP_UNIT_TYPE.COACHING)?.length ?? 0
  }

  get FlexUnits() {
    let modules: number[] = []
    let arrangedUnits =
      this.cachedFlexUnits ||
      (
        this.AllUnits.map((unit) => {
          const isFoundationUnit = !!this.FoundationUnits.find(
            ({ unitid }) => unitid === unit.unitid && unit.lpid !== this.learningPath?.lpid
          )
          const lpUnit = new PortalLearningPathUnit(
            unit,
            this.Program,
            new MyBerlitzLessons(this.FilterValidSessions),
            unit.lpid || this.learningPath?.lpid,
            this.IsJapanFlex,
            this.IsPreviousLP,
            !!this.ElectiveUnits.find(
              ({ unitid }) => unitid === unit.unitid && unit.lpid !== this.learningPath?.lpid
            ) ||
              (this.IsElective && !isFoundationUnit),
            isFoundationUnit,
            this.HasContractEnded,
            this.IsIndustrySpecificContent
          )
          lpUnit.FlexUnit = true
          lpUnit.CoachingCount = this.CoachingCount
          lpUnit.EnrolmentCompleted = !!(this.AYCLFlags && this.EnrolmentCompleted)
          if (unit.module) {
            modules[unit.module || 0] = unit.module
          }
          return lpUnit
        }) ?? []
      )?.filter((Unit) => !Unit.IsCourseMetaData)

    this.modules = this.modules || modules.filter((module) => module !== undefined)
    let coachingHolder
    if (!this.cachedFlexUnits) {
      // swap live coaching and review units
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < arrangedUnits.length; i++) {
        const currUnit = arrangedUnits[i]
        coachingHolder = arrangedUnits[i - 1]
        if (currUnit.Type === LP_UNIT_TYPE.CHECKPOINT && coachingHolder.Type === LP_UNIT_TYPE.COACHING) {
          coachingHolder.SiblingCheckpointUrl = currUnit.EntryPointUrl
          arrangedUnits[i - 1] = currUnit
          arrangedUnits[i++] = coachingHolder
        }
      }

      // set to unlimited lcs state when progress === 100 excluding review units
      const progress = this._getProgress(this._getProgressUnits(arrangedUnits, true))
      arrangedUnits.forEach((unit) => (unit.UnlimitedLCS = progress === 100))

      if (
        this.IsJapanFlex ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.UNLIMITED_LCS ||
        this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS ||
        (this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.LEVEL_STARTED &&
          arrangedUnits?.[0]?.UnlimitedLCS &&
          (this.IsMultiLvl || this.Material?.FlexVersion === FLEX_VERSION.SINGLE))
      ) {
        arrangedUnits = arrangedUnits.filter((Unit) => !Unit.IsLCSZero)
      }
      const hasLIS = arrangedUnits.find((Unit) => Unit.IsLCSZero)
      const buffer = hasLIS ? 0 : 1

      const withActionPlan = arrangedUnits
        .filter((item) => item.Type === LP_UNIT_TYPE.COACHING)
        .filter((_item, i) => (i + buffer) % 5 === 0 && i !== 0)
        .reduce((acc, cur) => {
          acc[cur.Id] = cur
          return acc
        }, {})

      arrangedUnits.map((Unit, index) => {
        if (withActionPlan[Unit.Id] && !Unit.ActionPlanUrl) {
          const prevUnit = arrangedUnits?.[index - 1]?.unit
          const actionPlan = prevUnit?.activities.find((Activity) => Activity?.type === FLEX_ACTIVITY_LINK)

          if (actionPlan) {
            const newAct = { ...actionPlan }
            const existingAct = Unit.unit?.activities.find((Activity) => Activity?.type === FLEX_ACTIVITY_LINK)
            const actionPlanAct = existingAct || newAct
            actionPlanAct.actionplanurl = actionPlan.url

            !existingAct ? Unit.unit?.activities.push(newAct) : null
          }
        }
      })
    }

    this.cachedFlexUnits = arrangedUnits
    return arrangedUnits
  }

  _getProgressUnits = (units: PortalLearningPathUnit[], excludeReviewUnits?: boolean) => {
    // remove lcs if material has no program
    // remove Lesson 0 from calculation
    return units?.filter(
      (unit) =>
        !unit.IsLCSZero &&
        !unit.IsLessonZero &&
        ((unit.Type !== LP_UNIT_TYPE.COACHING && this.IsJapanFlex) || !this.IsJapanFlex) &&
        unit.Type !== LP_UNIT_TYPE.CULTURE &&
        (this.IsFlex || (!this.IsFlex && unit.Type !== LP_UNIT_TYPE.MATERIAL)) && // these are the units that appears as orange card at the end of non flex lp
        (excludeReviewUnits ? !unit.IsReview : true)
    )
  }

  _getProgress = (units: PortalLearningPathUnit[], total?: number) => {
    let totalUnits = units.length
    if (this.FlexProgram || this.IsJapanFlex) {
      if (this.IsIndustrySpecificContent) {
        // not sure if 10 is the constant value so i'll use the actual total unit returned from api
        totalUnits = units.length
      } else {
        totalUnits = 60
      }
    } else if (this.IsOnDemand) {
      totalUnits = 40
    }

    return Math.floor(
      (units
        ?.filter(
          (unit) =>
            // all completed status units that are not LCS
            (unit.Status === LP_STATUS.COMPLETE && unit.Type !== LP_UNIT_TYPE.COACHING) ||
            // LCS that are unit-complete and has a lessog reg submission
            (unit.Status === LP_STATUS.COMPLETE && unit.Type === LP_UNIT_TYPE.COACHING && unit.SubmittedSession) ||
            // LCS that has a submitted session
            (unit.Type === LP_UNIT_TYPE.COACHING && unit.SubmittedSession) ||
            // incase unit is redone, check resent count consider as completed of greater than 0
            (!unit.IsCulture && unit.ResetCount)
        )
        // Review Lessons are optional and these are not part of the ‘Progression’ calculation in the Corporate Portal
        .filter((unit) => !unit.IsReview)?.length /
        (total ?? totalUnits)) *
        100
    )
  }

  getModuleProgress = (module: number) => {
    const mUnits = this._getProgressUnits(
      this.NonCultureUnits.filter((unit) => unit.module === module),
      true
    )
    return this._getProgress(mUnits, mUnits.length)
  }

  _filterOnDemandUnits = (unit: LMSLearningPlansUnits) =>
    this.IsOnDemand ? unit.unittype !== LP_UNIT_TYPE.COACHING : true

  get ProgressUnits() {
    return this._getProgressUnits(this.AllNonCultureUnits, true)
  }

  get Progress() {
    return this._getProgress(this.ProgressUnits)
  }

  get AYCLSequence() {
    return this.StudentProfile?.AYCLSequence(this.Material || ({} as RegistrationMaterial))
  }

  get NextLvl() {
    return Number(this.Material?.languageLevel || 0) + 1
  }

  get IsMultiLvl() {
    return (
      (this.AYCLFlags && this.Material?.FlexVersion === FLEX_VERSION.TWO) ||
      this.Material?.FlexVersion === FLEX_VERSION.MULTI
    )
  }

  get ReadyForNextLvl() {
    // Debug: show congratulations in hero card
    // return true
    return (
      this.AYCLFlags &&
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL &&
      this.IsMultiLvl &&
      !this.Material?.FlexLastLevel &&
      !this.EnrolmentInProgress
    )
  }

  get EnrolmentCompleted() {
    return this.AYCLFlags && this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ENROLMENT_COMPLETED
  }

  get EnrolmentInProgress() {
    return (
      this.AYCLFlags &&
      (this.FlexProgram || this.IsOnDemand) &&
      this.IsMultiLvl &&
      (this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS ||
        (this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL &&
          localStorage.getItem(this.tempFlags.EnrolmentInProgress) === 'true'))
    )
  }

  get IsAllLvlUnliLCS() {
    return this.AYCLFlags && this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.UNLIMITED_LCS && this.IsMultiLvl
  }

  // UNLIMITED LCS MODAL
  get HasRecentlyCompletedAllLCS() {
    return (
      this.FlexProgram &&
      // since onDemand has no LCS we need to check for progress
      (this.UnlimitedCoaching || (this.IsOnDemand && this.Progress === 100)) &&
      this.IsMultiLvl &&
      !this.Material?.FlexLastLevel &&
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.LEVEL_STARTED &&
      // this checking only applies to non on demand
      !this.IsOnDemand &&
      localStorage.getItem(this.tempFlags.UnlimitedCoaching) !== 'true'
    )
  }

  // all levels completed notifcard/banner
  get HasCompletedAllYouCanLearn() {
    return (
      this.AYCLFlags &&
      (this.FlexProgram || this.IsOnDemand) &&
      (this.Material?.FlexVersion === FLEX_VERSION.SINGLE || (this.IsMultiLvl && this.Material?.FlexLastLevel)) &&
      [FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL, FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED].indexOf(
        this.Material?.FlexLevelStatus || ''
      ) >= 0 &&
      (this.UnlimitedCoaching || (this.IsOnDemand && this.Progress === 100))
    )
  }

  // ALL LEVELS COMPLETED MODAL
  get IsRecentlyCompletedAllYouCanLearn() {
    // Debug: Always show the modal
    // return true

    return (
      (this.AYCLFlags &&
        !this.IsFlexLevelStatusUpdated &&
        (this.FlexProgram || this.IsOnDemand) &&
        [FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL].indexOf(this.Material?.FlexLevelStatus || '') >= 0 &&
        (this.Material?.FlexVersion === FLEX_VERSION.SINGLE || (this.IsMultiLvl && this.Material?.FlexLastLevel)) &&
        // since onDemand has no LCS we need to check for progress
        (this.UnlimitedCoaching || (this.IsOnDemand && this.Progress === 100))) ||
      (!this.IsFlex && this.Material?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED && !this.HasOpenedCTModal)
    )
  }

  get IsCTUnlock() {
    return this.Material?.TestStatus === CURRICULUM_TEST_STATUS.UNLOCKED
  }

  get HasOpenedCTModal() {
    return this.StudentProfile?.LPMaterialDropDown.some(
      ({ value, suboptions }) =>
        localStorage.getItem(AllLevelsCompletedModalLocalStorageKey(value, this.StudentProfile?.SFId)) === 'true' ||
        suboptions.some(
          ({ value }) =>
            localStorage.getItem(AllLevelsCompletedModalLocalStorageKey(value, this.StudentProfile?.SFId)) === 'true'
        )
    )
  }

  get IsFlexLevelStatusUpdated() {
    return !!localStorage.getItem(this.tempFlags.AllLevelsCompleted)
  }

  get IsPreviousLP() {
    return this.AYCLFlags && this.IsMultiLvl && this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ENROLMENT_COMPLETED
  }

  get Program() {
    return this.StudentProfile?.Programs[this.Material?.ProgramId || '']
  }

  get IsElective() {
    return this.Material?.IsElective && this.ElectivesFlag
  }

  get IsFoundation() {
    return (
      (!!this.Elective ||
        (this.Material?.ElectiveRequired &&
          this.Material?.ElectiveRequired !== ELECTIVE.NOT_REQUIRED &&
          !this.IsElective) ||
        this.Material?.IsCore) &&
      this.ElectivesFlag &&
      !this.IsElective
    )
  }

  get FlexProgram() {
    return this.Program?.isFlexProgram() ? this.Program : undefined
  }

  get Material() {
    return this.StudentProfile?.Materials?.[this.learningPath?.lpid || '']
  }

  get MaterialCore() {
    const Material = this.Material
    const FromRegistrationMaterials = Material?.FromRegistration.Materials
    return (
      FromRegistrationMaterials?.find(
        (material) => !material.IsElective && !material.IsTest && !material.IndustrySpecificContent
      ) || FromRegistrationMaterials?.[0]
    )
  }

  get IsJapanFlex() {
    // PTL-1479: is Flex if no program and programType is `Berlitz Flex - Private Face to Face Activation`
    return (
      !this.Program && [...PROGRAM_TYPES.JPN_FLEX, ...PROGRAM_TYPES.FLEX].indexOf(this.Material?.ProgramType || '') >= 0
    )
  }

  get IsOnDemand() {
    return [...PROGRAM_TYPES.ON_DEMAND].indexOf(this.Material?.ProgramType || '') >= 0
  }

  get IsIndustrySpecificContent() {
    return !!this.Material?.IndustrySpecificContent
  }

  get IsFlex() {
    // PTL-1390: LP is flex if it has no program
    return this.FlexProgram || this.IsJapanFlex || this.IsOnDemand || this.IsIndustrySpecificContent
  }

  get IsCTMaterial() {
    return this.Material?.IsTest ?? false
  }

  get AllUnits() {
    return [...(this.learningPath?.details?.units || []), ...this.ElectiveUnits, ...this.FoundationUnits].filter(
      this._filterOnDemandUnits
    )
  }

  get Units() {
    return this.IsFlex ? this.FlexUnits : this.NonFlexUnits
  }

  get AllNonCultureUnits() {
    return this.Units.filter((Unit) => Unit.Type !== LP_UNIT_TYPE.CULTURE)
  }

  get NonCultureUnits() {
    return this.Units.filter(
      (Unit) => Unit.Type !== LP_UNIT_TYPE.CULTURE && Unit.LearningPathId === this.learningPath?.lpid
    )
  }

  get CultureUnits() {
    return this.Units.filter(
      (Unit) => Unit.Type === LP_UNIT_TYPE.CULTURE && Unit.LearningPathId === this.learningPath?.lpid
    )
  }

  get CultureUnitsLanguages() {
    const languages = {}

    this.CultureUnits.forEach((Unit) =>
      Unit?.Activities?.filter((Activity) => Activity.Type === CULTURE_ACTIVITY_TYPE)?.forEach((Filtered) => {
        if (Filtered.LanguageCode) {
          languages[Filtered.LanguageCode] = Filtered.LanguageName
        }
      })
    )

    return languages
  }

  get FirstUnit() {
    return this.Units.find((Unit) => Unit.IsCoaching)
  }

  get FirstLockedUnit() {
    return this.Units.find((Unit) => Unit.IsCoaching && Unit.Status === LP_STATUS.LOCKED)
  }

  get LatestUnlockedUnit() {
    return this.Units.find((Unit) => Unit.IsCoachingActive) || this.FirstLockedUnit || this.FirstUnit
  }

  get LCSUnits() {
    return this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING)
  }

  get NextSessionUnit() {
    let nextSession: PortalLearningPathUnit | null | undefined
    switch (this.LPLCSStatus) {
      case LP_STATUS.CUSTOM_BOOKED:
        nextSession =
          this.UnlockedLCS.find((lcs) => lcs.Id === Number(this.StudentSessions?.OngoingLPSession?.lesson?.UnitId)) ||
          this.LatestUnlockedUnit ||
          this.CompletedOrOngoingLCS?.[this.CompletedOrOngoingLCS.length - 1]
        break
      case LP_STATUS.UNLOCKED:
        nextSession = last(this.BookableLCS)
        break
      case LP_STATUS.COMPLETE:
        nextSession = this.LCSUnits?.[this.LCSUnits.length - 1]
        break
      default:
        nextSession = this.LockedLCS?.[0] || this.LatestUnlockedUnit
    }

    if (nextSession) {
      nextSession.Unlimited = this.UnlimitedCoaching
    }

    return nextSession
  }

  get ValidSessions() {
    return this.StudentSessions?.Sessions.filter((Session) => Session.ProgramId === this.Program?.ProgramId)
  }

  get FilterValidSessions() {
    return this.StudentSessions?.lessons?.filter((lesson) => lesson.ProgramId === this.Program?.ProgramId)
  }

  get CoachingSessionsCount() {
    return this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING)?.length
  }

  get UnlockedLCS() {
    if (this.UnlimitedCoaching || this.Registration?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL) {
      // return all unit type coaching if all coaching units are completed
      return this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING)
    }
    return this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING && Unit.Status === LP_STATUS.UNLOCKED)
  }

  get LockedLCS() {
    return this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING && Unit.Status === LP_STATUS.LOCKED)
  }

  get HasCompletedAllLCS() {
    const completed = this.CompletedOrOngoingLCS.length
    return completed && completed === this.LCSUnits.length
  }

  get CompletedOrOngoingLCS() {
    return this.Units?.filter((unit) => unit.Type === LP_UNIT_TYPE.COACHING && unit.Session)
  }

  get AllLCS() {
    return this.Units?.filter((unit) => unit.Type === LP_UNIT_TYPE.COACHING).map((unit) => {
      unit.Order = 0
      const regex = /\d+/
      const match = unit?.Title?.match(regex)
      if (match) {
        unit.Order = parseInt(match[0])
      }
      return unit
    })
  }

  get CompletedLCS() {
    // primarily used for userflow to count number of completed lcs
    return this.AllLCS.filter((unit) => unit.Session && unit.Session.isPastLesson() && !unit.IsLCSZero)
  }

  get HasCompletedLIS() {
    // primarily used for userflow to check if LIS is completed
    return this.Units?.some(
      (unit) => unit.Type === LP_UNIT_TYPE.COACHING && unit.Session && unit.Session.isPastLesson() && unit.IsLCSZero
    )
  }

  get AllFlexBODLessons() {
    return this.Units?.filter((unit) => unit.Type === LP_UNIT_TYPE.LESSON && unit.IsFlex).map((unit) => {
      unit.Order = 0
      const regex = /\d+/
      const match = unit?.Title?.match(regex)
      if (match) {
        unit.Order = parseInt(match[0])
      }
      return unit
    })
  }

  get CompletedFlexBODLessons() {
    // primarily used for userflow to count number of completed lcs
    return this.AllFlexBODLessons.filter((unit) => unit.Status === LP_STATUS.COMPLETE)
  }

  get AllLOLessons() {
    return this.Units?.filter((unit) => unit.Type === LP_UNIT_TYPE.LESSON && !unit.IsFlex).map((unit) => {
      unit.Order = 0
      const regex = /\d+/
      const match = unit?.Title?.match(regex)
      if (match) {
        unit.Order = parseInt(match[0])
      }
      return unit
    })
  }

  get CompletedLOLessons() {
    // primarily used for userflow to count number of completed live online
    return this.AllLOLessons.filter((unit) => unit.LessonStatus === LP_UNIT_LESSON_STATUS.COMPLETE)
  }

  get CompletedCheckpoints() {
    // primarily used for userflow to count number of completed checkpoints
    return this.Units?.filter((unit) => unit.Type === LP_UNIT_TYPE.CHECKPOINT)
      .map((unit, index) => {
        unit.Order = index + 1
        return unit
      })
      .filter((unit) => unit.Status === LP_STATUS.COMPLETE)
  }

  get Level() {
    return this.learningPath?.details.level
  }

  get HasOngoingLCS() {
    return this.StudentSessions?.HasOngoingLPSession
  }

  get HasCompletedAllCoachingUnits() {
    return (
      this.CoachingCount ===
      this.Units?.filter((Unit) => Unit.Type === LP_UNIT_TYPE.COACHING && Unit.Status === LP_STATUS.COMPLETE).length
    )
  }

  get CanBookCompletedLCS() {
    return (
      this.IsActiveFlexRegistration &&
      (this.UnlimitedCoaching || this.Registration?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL)
    )
  }

  get BookableLCS() {
    if (this.CanBookCompletedLCS) {
      if (!this.HasCompletedAllLCS) {
        return this.LCSUnits.filter(({ UnitStatus }) => UnitStatus !== LP_STATUS.LOCKED)
      }

      return this.UnlockedLCS
    }

    return this.UnlockedLCS.filter((lcs) => {
      return (
        !lcs.Session ||
        lcs.Session.lesson?.LPId !== this.learningPath?.lpid ||
        lcs.Session?.lesson?.Attendance === 'Absent'
      )
    })
  }

  // used in calendar to determine if booking is available or not
  get LCSStatus() {
    return this.HasOngoingLCS
      ? LP_STATUS.CUSTOM_BOOKED
      : !this.BookableLCS.length && (this.UnlockedLCS.length || this.HasCompletedAllLCS) && !this.LockedLCS.length
        ? LP_STATUS.COMPLETE
        : this.BookableLCS.length
          ? LP_STATUS.UNLOCKED
          : LP_STATUS.LOCKED
  }
  // used in learning path to determine learning path status
  get LPLCSStatus() {
    return this.HasOngoingLCS && this.StudentSessions?.OngoingLPSession?.lesson?.LPId === this.learningPath?.lpid
      ? LP_STATUS.CUSTOM_BOOKED
      : !this.BookableLCS.length && (this.UnlockedLCS.length || this.HasCompletedAllLCS) && !this.LockedLCS.length
        ? LP_STATUS.COMPLETE
        : this.BookableLCS.length
          ? LP_STATUS.UNLOCKED
          : LP_STATUS.LOCKED
  }

  get NotYetStartedLessons() {
    return this.Units.filter(
      ({ IsNotYetStarted, IsLesson, Type }) => IsNotYetStarted && IsLesson && Type === LP_UNIT_TYPE.LESSON
    )
  }

  get InProgressLessons() {
    return this.Units.filter(
      ({ IsInProgress, IsLesson, Type }) => IsInProgress && IsLesson && Type === LP_UNIT_TYPE.LESSON
    )
  }

  get AreAllUnitsCompleted() {
    return this.Units.filter(({ IsLesson, Type }) => IsLesson && Type === LP_UNIT_TYPE.LESSON).every(
      ({ IsInProgress, IsNotYetStarted }) => !IsInProgress && !IsNotYetStarted
    )
  }

  get CompletedButFlexInprogressLessons() {
    // remove lesson zero from dashboard display when completed
    return this.Units.filter(
      ({ unit, IsLesson, IsLessonZero }) =>
        unit?.flexstatus === LP_STATUS.IN_PROGRESS && unit?.resetcount > 0 && IsLesson && !IsLessonZero
    ).sort(
      (a, b) =>
        new Date(b.unit?.latestattemptdate || new Date()).valueOf() -
        new Date(a.unit?.latestattemptdate || new Date()).valueOf()
    )
  }

  get CompletedAndFlexCompletedLessons() {
    // remove lesson zero from dashboard display when completed
    return this.Units.filter(
      ({ unit, IsLesson, IsLessonZero }) => unit?.flexstatus === LP_STATUS.COMPLETE && IsLesson && !IsLessonZero
    ).sort((a, b) => (a.unit?.order || 0) - (b.unit?.order || 0))
  }

  get DashboardActivities() {
    const incompleteUnits = this.NotYetStartedLessons.slice(0, 2)
    const inProgressUnits = this.InProgressLessons.sort(
      (a, b) =>
        new Date(b.unit?.latestattemptdate || new Date()).valueOf() -
        new Date(a.unit?.latestattemptdate || new Date()).valueOf()
    ).slice(0, 2)
    return [...incompleteUnits.slice(0, 2 - inProgressUnits.length), ...inProgressUnits]
  }

  get DashboardCompletedActivities() {
    const inprogress = this.CompletedButFlexInprogressLessons.slice(0, 2)
    const completed = this.CompletedAndFlexCompletedLessons.slice(0, 2)

    return [...inprogress, ...completed.slice(0, 2 - inprogress.length)]
  }

  get DashboardUnits() {
    const incompleteUnits = this.NotYetStartedLessons.slice(0, 3)
    const inProgressUnits = this.InProgressLessons.sort(
      (a, b) =>
        new Date(b.unit?.latestattemptdate || new Date()).valueOf() -
        new Date(a.unit?.latestattemptdate || new Date()).valueOf()
    ).slice(0, 3)

    return [...incompleteUnits.slice(0, 3 - inProgressUnits.length), ...inProgressUnits]
  }

  get DashboardCompletedUnits() {
    const units = this.Units.filter(
      ({ IsLesson, IsLessonZero, Type }) => IsLesson && Type === LP_UNIT_TYPE.LESSON && !IsLessonZero
    )
    return units.splice(0, 3)
  }

  get IsPased2Days() {
    if (this.Material?.UnlimitedLiveCoachingDate) {
      return moment(new Date()).isAfter(addBusinessDays(this.Material?.UnlimitedLiveCoachingDate, 2))
    } else {
      return false
    }
  }

  get OriginalFlexUnits() {
    let arrangedUnits = (
      this.learningPath?.details?.units?.map((unit) => {
        const lpUnit = new PortalLearningPathUnit(
          unit,
          this.Program,
          new MyBerlitzLessons(this.FilterValidSessions),
          this.learningPath?.lpid,
          this.IsJapanFlex,
          this.IsPreviousLP
        )
        lpUnit.FlexUnit = true
        lpUnit.CoachingCount = this.CoachingCount
        lpUnit.EnrolmentCompleted = this.AYCLFlags && this.EnrolmentCompleted
        return lpUnit
      }) ?? []
    )?.filter((Unit) => !Unit.IsCourseMetaData)

    let coachingHolder

    // swap live coaching and review units
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < arrangedUnits.length; i++) {
      const currUnit = arrangedUnits[i]
      coachingHolder = arrangedUnits[i - 1]
      if (currUnit.Type === LP_UNIT_TYPE.CHECKPOINT && coachingHolder.Type === LP_UNIT_TYPE.COACHING) {
        coachingHolder.SiblingCheckpointUrl = currUnit.EntryPointUrl
        arrangedUnits[i - 1] = currUnit
        arrangedUnits[i++] = coachingHolder
      }
    }

    // set to unlimited lcs state when progress === 100 excluding review units
    const progress = this._getProgress(this._getProgressUnits(arrangedUnits, true))
    arrangedUnits.forEach((unit) => (unit.UnlimitedLCS = progress === 100))

    if (
      this.IsJapanFlex ||
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ALL_LEVELS_COMPLETED ||
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.FOR_NEXT_LEVEL ||
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.UNLIMITED_LCS ||
      this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.ENROLMENT_INPROGRESS ||
      (this.Material?.FlexLevelStatus === FLEX_LEVEL_STATUS.LEVEL_STARTED &&
        arrangedUnits?.[0]?.UnlimitedLCS &&
        (this.IsMultiLvl || this.Material?.FlexVersion === FLEX_VERSION.SINGLE))
    ) {
      arrangedUnits = arrangedUnits.filter((Unit) => !Unit.IsLCSZero)
    }
    const hasLIS = arrangedUnits.find((Unit) => Unit.IsLCSZero)
    const buffer = hasLIS ? 0 : 1

    const withActionPlan = arrangedUnits
      .filter((item) => item.Type === LP_UNIT_TYPE.COACHING)
      .filter((_item, i) => (i + buffer) % 5 === 0 && i !== 0)
      .reduce((acc, cur) => {
        acc[cur.Id] = cur
        return acc
      }, {})

    arrangedUnits.map((Unit, index) => {
      if (withActionPlan[Unit.Id] && !Unit.ActionPlanUrl) {
        const prevUnit = arrangedUnits?.[index - 1]?.unit
        const actionPlan = prevUnit?.activities.find((Activity) => Activity?.type === FLEX_ACTIVITY_LINK)

        if (actionPlan) {
          const newAct = { ...actionPlan }
          const existingAct = Unit.unit?.activities.find((Activity) => Activity?.type === FLEX_ACTIVITY_LINK)
          const actionPlanAct = existingAct || newAct
          actionPlanAct.actionplanurl = actionPlan.url

          !existingAct ? Unit.unit?.activities.push(newAct) : null
        }
      }
    })

    return arrangedUnits
  }

  pristineClone() {
    return new PortalLearningPath(this.learningPath, this.StudentProfile, this.StudentSessions)
  }

  get CtEntryPoint() {
    return this.CurriculumTests?.[0] ?? null
  }

  get Registration() {
    return new PortalRegistrations(this.StudentProfile?.student?.RegistrationWrappers).getRegistrationByProgId(
      this.Program?.ProgramId || ''
    )
  }

  get IsActiveFlexRegistration() {
    const isFlex = FLEX.some((type) => type.toLocaleLowerCase() === this.Program?.program?.ProgramType.toLowerCase())
    const isInActive = [REG_STATUS.EXPIRED, REG_STATUS.DRAFT, REG_STATUS.COMPLETED, REG_STATUS.CANCELLED].some(
      (regStatus) => regStatus.toLocaleLowerCase() === this.Registration?.Status.toLowerCase()
    )

    return isFlex && !isInActive
  }

  get Modules() {
    if (this.IsFlex) {
      // calling flexunits getter to initialize modules
      this.FlexUnits
      return this.modules
    }

    return []
  }

  get ISCTitle() {
    const postfix = this.Material?.MaterialLevel < 5 ? 'Basic' : 'Advanced'
    return `${this.Material?.partialName} ${postfix}`
  }

  getModuleByCMID(cmid: string) {
    const regex = new RegExp(`cmid=${cmid}(?![0-9])`)
    const unit = this.NonCultureUnits.find((unit) => unit.Rounds.some((round) => regex.test(round?.url)))
    return unit?.module
  }

}

export default PortalLearningPath
