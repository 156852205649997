import moment from 'moment'
import { IntlShape } from 'react-intl'

export interface ILessonCountdownProps {
  StartTime?: string
  EndTime?: string
  tz?: string
}

class LessonCountdown {
  StartTime: string
  EndTime: string
  tz: string

  constructor(props: ILessonCountdownProps) {
    this.StartTime = props.StartTime || ''
    this.EndTime = props.EndTime || ''
    this.tz = props.tz || moment.tz.guess()
  }

  get timeLeftBeforeStart() {
    return moment.duration(moment(this.StartTime).add(1, 'minute').diff(moment()))
  }

  get daysLeftBeforeStart() {
    // we need to get the end day time of the start time as we're not going to
    // consider the time component of the lesson when checking for the number of days left
    const start = moment(this.StartTime).tz(this.tz)
    return moment.duration(start.endOf('day').diff(moment()))
  }

  get isPastEndTime() {
    const timeLeft = moment.duration(moment(this.EndTime).diff(moment()))
    return timeLeft.milliseconds() <= 0
  }

  isInProgress() {
    return moment().isBetween(this.StartTime, this.EndTime)
  }

  get isMinutesLeft() {
    return (
      Math.floor(this.timeLeftBeforeStart.asDays()) === 0 &&
      this.timeLeftBeforeStart.hours() === 0 &&
      this.timeLeftBeforeStart.minutes() > 0
    )
  }

  shouldShowCountdown = () => {
    const timeLeft = this.timeLeftBeforeStart
    const daysLeft = this.daysLeftBeforeStart
    return (
      daysLeft.asDays() <= 7 &&
      (timeLeft.minutes() > 0 || timeLeft.hours() > 0 || daysLeft.asDays() > 0 || timeLeft.asDays() > 0)
    )
  }

  formatCountdown = (intl: IntlShape) => {
    const timeLeft = this.timeLeftBeforeStart
    const daysLeft = this.daysLeftBeforeStart
    const days = Math.floor(daysLeft.asDays())
    const exactDays = Math.floor(timeLeft.asDays())
    const hrs = timeLeft.hours()
    const min = timeLeft.minutes()

    if (days <= 7 && exactDays > 0) {
      return intl.formatMessage(
        {
          id: 'next-lesson-days-count',
          defaultMessage: `{days, plural, one {# day} other {# days}}`,
        },
        { days }
      )
    } else if (exactDays === 0 && hrs > 0) {
      return intl.formatMessage(
        {
          id: 'next-lesson-days-h-m',
          defaultMessage: `in {hrs}h {min}min`,
        },
        { hrs, min }
      )
    } else if (exactDays === 0 && hrs === 0 && min > 0) {
      return intl.formatMessage(
        {
          id: 'next-lesson-in-min',
          defaultMessage: `{min, plural, one {in # minute} other {in # minutes}}`,
        },
        { min }
      )
    }
  }
}

export default LessonCountdown
