import React from 'react'
import { Container } from './style'
import { useIntl } from 'react-intl'
import PlacementTestMessage from '@berlitzplatforms/micro.ui.placement-test-message'
import Router from 'next/router'
import { useSelector } from 'react-redux'
import PortalStudentProfile from '@classes/StudentProfile'
import { StoreProps } from '@interfaces/StoreState'
import { useDispatch } from 'react-redux'
import { updateDashboardActiveMaterial } from '@redux/actions/dashboard'

const NotSetBanner: React.FC = () => {
  const intl = useIntl()
  const { activeProgram, profile } = useSelector(({ userProfile, dashboard }: StoreProps) => ({
    activeProgram: dashboard.activeProgram,
    profile: new PortalStudentProfile(userProfile.info.StudentProfile?.StudentProfileInfo),
  }))

  const dispatch = useDispatch()

  return (
    <Container>
      <PlacementTestMessage
        title={intl.formatMessage({
          id: 'Test your language skills',
        })}
        message={intl.formatMessage({
          id: 'Return to the dashboard to take the placement test to identify your language level and start learning.',
        })}
        buttonLabel={intl.formatMessage({
          id: 'Go to my dashboard',
        })}
        onBtnClick={() => {
          const registration = profile?.student?.RegistrationWrappers.find(
            (reg) => reg?.RegistrationInfo?.Programs?.[0].ProgramId === activeProgram
          )

          dispatch(updateDashboardActiveMaterial(registration?.RegistrationInfo?.RegistrationId ?? ''))
          Router.push('/')
        }}
      />
    </Container>
  )
}

export default NotSetBanner
