import { ProgramStudentInfo as IProgramStudentInfo } from '@interfaces/Student'
import { DATE_FORMAT, FINISHED_PROGRAM_TYPES, PROGRAM_TYPES, REG_STATUS, VALID_PROGRAM_TYPES } from '@utils/constants'
import { getPermissionFromStorage } from '@utils/helpers/permissions'
import { Moment } from 'moment'
import moment from 'moment-timezone'

class PortalProgram {
  program?: IProgramStudentInfo
  orientationBlocker?: boolean
  FMIBulkBook?: boolean
  timezone: string
  constructor(program?: IProgramStudentInfo, timezone?: string) {
    this.program = program
    this.orientationBlocker = getPermissionFromStorage('flag::Orientation-blocker')
    this.FMIBulkBook = getPermissionFromStorage('flag::FMI-bulkbooking')
    this.timezone = timezone || moment.tz.guess()
  }

  isProgramFinished = () =>
    FINISHED_PROGRAM_TYPES.map((type) => type.toLowerCase()).includes((this.program?.Status || '').toLowerCase())

  isProgramOnline = () =>
    PROGRAM_TYPES.ONLINE.map((type) => type.toLowerCase()).includes((this.program?.ProgramType || '').toLowerCase()) ||
    this.program?.DeliveryMode?.toLowerCase() === 'online'

  isFlexProgram = () =>
    PROGRAM_TYPES.FLEX.some((program) =>
      program.toLowerCase().includes((this.program?.ProgramType || '').toLowerCase())
    )

  isProgramLiveOnline = (): boolean =>
    PROGRAM_TYPES.LIVE_ONLINE.map((type) => type.toLowerCase()).includes(
      (this.program?.ProgramType || '').toLowerCase()
    )

  isProgramLiveOnlineInGroup = (): boolean =>
    !!(
      PROGRAM_TYPES.LIVE_ONLINE.map((type) => type.toLowerCase()).includes(
        (this.program?.ProgramType || '').toLowerCase()
      ) && this.program?.IsGroup
    )

  isProgramBlocked = () => this.program?.Status === 'Pause' || this.program?.BlockScheduling

  isProgramValidForScheduling = (): boolean =>
    VALID_PROGRAM_TYPES.map((type) => type.toLowerCase()).includes((this.program?.Status ?? '').toLowerCase())

  isProgramF2F = (): boolean =>
    PROGRAM_TYPES.F2F.some((program) => program.toLowerCase().includes((this.program?.ProgramType || '').toLowerCase()))

  isProgramForOrientation = (): boolean => !!this.IsOrientationRequired

  isProgramNonLeadGroupMember = (): boolean => !this.isStudentAbleToBook

  isReadOnly(isRebooking: boolean) {
    return !this.isStudentAbleToBook || (!isRebooking && this.IsLessonsBookedOut) || this.IsOrientationRequired || false
  }

  get IsReadOnly() {
    return !this.isStudentAbleToBook || this.IsLessonsBookedOut || this.IsOrientationRequired || false
  }

  get isStudentAbleToBook() {
    return !this.program?.IsGroup || (this.program.IsGroup && this.program.IsLead)
  }

  get IsLessonsBookedOut() {
    return (
      [REG_STATUS.ACTIVE, REG_STATUS.PENDING_START].includes(this.program?.Status || '') &&
      !this.program?.LessonsRemaining
    )
  }

  get IsOrientationRequired() {
    return (
      !this.program?.IsGroup &&
      [REG_STATUS.ACTIVE, REG_STATUS.PENDING_START].includes(this.program?.Status || '') &&
      this.program?.ValidforOrientation &&
      (!this.program?.OrientationDate || this.program?.OrientationDate === null) &&
      this.orientationBlocker
    )
  }

  get isGroupLead() {
    return this.program?.IsGroup && this.program.IsLead
  }

  hasExcessUnbookedLessons = (selectedLessons: any[] = [], isRebook: boolean = false) => {
    const LessonsPerSession = this.program?.LessonsPerSession || 0
    const LessonsRemaining = (this.program?.LessonsRemaining || 0) + (isRebook ? LessonsPerSession : 0)
    const lessonCount = selectedLessons.length

    const lessonsUnbooked = LessonsRemaining - lessonCount * LessonsPerSession
    const maxSelect = LessonsRemaining / LessonsPerSession
    const excess = maxSelect - lessonCount

    return (!lessonCount && LessonsPerSession > LessonsRemaining) || (LessonsPerSession > lessonsUnbooked && excess > 0)
  }

  getExcessLessonsCount = (selectedLessons: any[] = []) => {
    const LessonsPerSession = this.program?.LessonsPerSession || 0
    const LessonsRemaining = this.program?.LessonsRemaining || 0
    return selectedLessons.length > 0 ? LessonsRemaining - selectedLessons.length * LessonsPerSession : LessonsRemaining
  }

  enoughLessonLeftPerSession = (): boolean =>
    !!this.program?.LessonsRemaining &&
    !!this.program?.LessonsPerSession &&
    this.program.LessonsRemaining >= this.program.LessonsPerSession

  programHasActiveLiveCoaching = (): boolean =>
    this.isFlexProgram() && (this.program?.TotalSessionsComplete || 0) < (this.program?.LessonsBooked || 0)

  isProgramSetupCorrectly = () =>
    this.enoughLessonLeftPerSession() &&
    !this.isProgramBlocked() &&
    (this.program?.BulkScheduling || this.program?.SingleSessionScheduling)

  getContractEndDate = () =>
    this.program?.ContractEndDate ? moment(this.program?.ContractEndDate, DATE_FORMAT.BASIC).toDate() : new Date()

  getLessonsLeft = () => this.program?.LessonsRemaining || 0

  hasSingleAndBulkFeature = () => this.program?.SingleSessionScheduling && this.program?.BulkScheduling

  hasBulkOnlyFeature = () => !this.program?.SingleSessionScheduling && this.program?.BulkScheduling

  isEligibleForFMI = () => this.IsActive && !this.isFlexProgram() && this.FMIBulkBook && this.isFMIEnabled()

  hasFeature = (feature: 'SingleSessionScheduling' | 'BulkScheduling') => Boolean(this.program?.[feature])

  promoteFMIBulk = () => this?.hasBulkOnlyFeature() && !this?.program?.LessonsBooked && this.isEligibleForFMI()

  isChildProgram = () => !!this.program?.ParentProgramId

  areLessonsAllBooked = () => !(this.isProgramValidForScheduling() && this.enoughLessonLeftPerSession())

  getCutOffTime = (userTZ: string, cutOffMinutes: number = 0) => {
    if (
      !this.program?.CutOffTimeOverride &&
      !this.program?.CutOffTime &&
      !this.program?.HoursBeforeCutOff &&
      !this.program?.SchedulingMinutesBeforeCutoff &&
      !this.program?.CancelCutOffMinutes
    ) {
      return moment().add(1, 'd')
    }

    const userDate = moment().tz(userTZ || moment.tz.guess())
    const dateWithUserTimezone = (date) => moment.tz(date ? date : moment(), userTZ)
    let minDate
    const cutoffTime = this.program?.CutOffTimeOverride || this.program?.CutOffTime

    if (cutoffTime) {
      const tz = this.program?.DeliveryLCTimeZone ?? userTZ ?? moment.tz.guess()
      const currentTime = moment().tz(tz)
      const cutoff = moment.tz(
        `${currentTime.format(DATE_FORMAT.DEFAULT)}, ${cutoffTime ?? '05:00 PM'}`,
        DATE_FORMAT.COMPLETE,
        tz
      )

      minDate = currentTime.add(currentTime.isBefore(cutoff) ? 1 : 2, 'd').startOf('day')
    } else {
      minDate = userDate.add(cutOffMinutes || this.program?.HoursBeforeCutOff || 0, cutOffMinutes ? 'm' : 'h')
    }

    if (this.program?.ProgramEnrollDate) {
      const enrollDate = dateWithUserTimezone(this.program.ProgramEnrollDate)
      minDate = enrollDate.isSameOrAfter(userDate) ? enrollDate : minDate
    }

    if (this.program?.ValidforOrientation && this.program?.OrientationDate) {
      const orientation = dateWithUserTimezone(this.program.OrientationDate)
      minDate = orientation.isSameOrAfter(minDate) ? orientation : minDate
    }
    return minDate
  }

  isAfterCutOffTime = (time: Moment, cutOffMinutes: number = 0) => {
    const tz = time.tz() || moment.tz.guess()
    if (!time.isValid()) {
      return false
    }

    return time.isBefore(this.getCutOffTime(tz, cutOffMinutes))
  }

  getProgramContractEndDate = (userTZ: string) => {
    return moment.tz(moment(this.ContractEndDate, DATE_FORMAT.BASIC) || moment(), userTZ)
  }

  getSessionSearchEndDate = (userTZ: string) => {
    const contractEnd = moment.tz(moment(this.ContractEndDate, DATE_FORMAT.BASIC) || moment(), userTZ).endOf('day')
    const today = moment()
    return contractEnd.isBefore(today) ? contractEnd : today
  }

  get _rescheduleCount() {
    return (this.program?.TotalRescheduleAllowed ?? 0) - (this.program?.RescheduleCount ?? 0)
  }

  get _cancelsRemaining() {
    return (this.program?.TotalCancellationAllowed ?? 0) - (this.program?.CancelCount ?? 0)
  }

  get _showLessonActions() {
    return !this.program?.IsGroup || (this.program?.IsGroup && this.program?.IsPrivate)
  }

  get _hasCancelAddOn() {
    return this._cancelsRemaining > 0
  }

  get LPIds() {
    return this.program?.Materials?.map(({ LPID }) => LPID)
  }

  get LMSMaterialId() {
    return this.program?.Materials?.[0]?.LPID
  }

  get ContractEndDate() {
    const format = 'YYYY-MM-DD'
    return this.isFlexProgram()
      ? moment(this.program?.ContractEndDate, format).format(format)
      : moment(this.program?.ContractEndDate, format)
          .tz(this.program?.DeliveryLCTimeZone || moment.tz.guess())
          .format(format)
  }

  get HasContractEnded() {
    const endDate = this.ContractEndDate
    return endDate && this.isFlexProgram() ? moment(endDate).add(1, 'day').isBefore(moment.tz(this.timezone)) : false
  }

  get ProgramId() {
    return this.program?.ProgramId
  }

  get ProgramEnrollDate() {
    return this.program?.ProgramEnrollDate
  }

  get ProgramTypeDisplay() {
    const maxNumOfStudents = this.program?.studentGroupInfo?.MaximumOfStudents
    return this.program?.ProgramType
      ? this.program?.ProgramType + (maxNumOfStudents ? ` (${this.program?.studentGroupInfo?.MaximumOfStudents})` : '')
      : undefined
  }

  get ClassroomProvider() {
    return this.program?.VirtualClassRoomProvider
  }

  get IsActive() {
    return (
      !this.isProgramBlocked() &&
      this.program?.Status !== REG_STATUS.CANCELLED &&
      this.program?.Status !== REG_STATUS.COMPLETED &&
      this.program?.Status !== REG_STATUS.DRAFT &&
      this.program?.Status !== REG_STATUS.EXPIRED
    )
  }

  get IsRescheduleAvailable() {
    return (this.program?.TotalRescheduleAllowed ?? 0) > (this.program?.RescheduleCount ?? 0)
  }

  get IsLessonsRemainingBelowOfMinToBeBooked() {
    return (this.program?.LessonsRemaining || 0) < (this.program?.MinimumNumberOfLessonsToBeBooked || 0)
  }

  get IsFMICalendarBulkBookingAllowed() {
    return this.FMIBulkBook && this.hasBulkOnlyFeature() && this.IsLessonsRemainingBelowOfMinToBeBooked
  }

  isLessonsCountReachedMinimumBooking = (lessons: any[] = []) => {
    const LessonsPerSession = this.program?.LessonsPerSession || 1
    const MinimumNumberOfLessonsToBeBooked = this.program?.MinimumNumberOfLessonsToBeBooked || 0
    const lessonsCount = lessons.length * LessonsPerSession
    return lessonsCount >= MinimumNumberOfLessonsToBeBooked
  }

  disableFMIBulkBooking = (lessons: any[] = []) =>
    this.FMIBulkBook && this.hasBulkOnlyFeature() && !this.isLessonsCountReachedMinimumBooking(lessons)

  isEnrollDateAfterUserDate = (timeoverride?: string) => {
    const time = moment(timeoverride || moment())
    const tz = time.tz() || moment.tz.guess()
    const userDate = moment.tz(tz)
    const enrollDate = moment.tz(this?.ProgramEnrollDate ? this?.ProgramEnrollDate : moment(), tz)
    return enrollDate.isAfter(userDate, 'date')
  }

  get TotalRescheduleAllowed() {
    return this.program?.TotalRescheduleAllowed || 0
  }

  get RescheduleCount() {
    return this.program?.RescheduleCount || 0
  }

  get LessonsRemaining() {
    return this.program?.LessonsRemaining || 0
  }

  get LessonsPerSession() {
    return this.program?.LessonsPerSession || 0
  }

  get IsGroupConversation() {
    return this.program?.GroupConversationClasses ?? false
  }

  isRebookLessonDisabled = (NoOfLessons = 0) =>
    this.TotalRescheduleAllowed > this.RescheduleCount && this.LessonsPerSession > NoOfLessons

  isFMIEnabled = () => {
    return this.program?.ProgramType !== PROGRAM_TYPES.ON_DEMAND_1[0]
  }

  isBerlitzOnDemand1 = () => {
    return this.program?.ProgramType === PROGRAM_TYPES.ON_DEMAND_1[0]
  }

  isBerlitzOnDemand = () => {
    return this.program?.ProgramType === PROGRAM_TYPES.ON_DEMAND[0]
  }
}

export default PortalProgram
